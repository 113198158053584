define(['lodash',
    'coreUtilsLib',
    'skins/util/skins',
    'skins/util/skinsToBeDeleted',
    'experiment'], function (_,
                             coreUtilsLib,
                             skinsJson,
                             skinsToBeDeleted,
                             experiment) {
    'use strict';

    return {
        /***
         *
         * @param {string} skinName
         * @param {function} [isExperimentOpen] if not provided will use experiment.isOpen without siteData
         * @returns {object|undefined} Skin object by name, returns undefined if skin not found
         */
        get(skinName, isExperimentOpen) {
	        isExperimentOpen = isExperimentOpen || experiment.isOpen;

	        if (skinsToBeDeleted[skinName] && isExperimentOpen('sv_markSkinsForDeletion')) {
		        coreUtilsLib.log.error('Requested a skin that is marked for deletion', skinName);
	        }

	        const skin = skinsJson[skinName];

            if (!skin) {
	            if (_.isNil(skinName)) {
		            return;
	            }

	            /**
                 * svgshape skins are not loaded to the skinsJson
                 */
                if (!_.startsWith(skinName, 'svgshape.')) {
                    coreUtilsLib.log.error('Required skin wasn\'t loaded yet', skinName);
                }
            }

            return skin;
        },
        /***
         *
         * @param {object} skinsMap - map of skins to add to the map
         */
        addBatch(skinsMap) {
            _.assign(skinsJson, skinsMap);
        }
    };
});
